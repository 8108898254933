import { useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import LogoTitle from '../../assets/images/logo_home.png'
import Cv from '../../assets/images/cv.webp'
import './style.scss'

function Home() {
  const [letterClass] = useState('text-animate')

  const nameArray = ['o', 'm', 'a', 'i', 'n']
  const jobArray = [
    'd',
    'é',
    'v',
    'e',
    'l',
    'o',
    'p',
    'p',
    'e',
    'u',
    'r',
    ' ',
    'w',
    'e',
    'b',
    '.',
  ]

  return (
    <div>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>B</span>
            <span className={`${letterClass} _12`}>o</span>
            <span className={`${letterClass} _13`}>n</span>
            <span className={`${letterClass} _14`}>j</span>
            <span className={`${letterClass} _15`}>o</span>
            <span className={`${letterClass} _16`}>u</span>
            <span className={`${letterClass} _17`}>r</span>

            <br />

            <span className={`${letterClass} _18`}>j</span>
            <span className={`${letterClass} _19`}>e</span>
            <span className={`${letterClass} _20`}>&nbsp;</span>
            <span className={`${letterClass} _21`}>s</span>
            <span className={`${letterClass} _22`}>u</span>
            <span className={`${letterClass} _23`}>i</span>
            <span className={`${letterClass} _24`}>s</span>

            <img
              src={LogoTitle}
              alt="premiere lettre du prénom du créateur du site"
              className="initial"
            />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={25}
            />

            <br />

            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={20}
            />
          </h1>

          <Link to="/contact" className="flat-button">
            CONTACT
          </Link>
        </div>

        <div className="cv">
          <a
            href="https://drive.google.com/file/d/1mOWRghKyErsPNFMytZyiXL_nTfJmA_lq/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="cv-wrapper">
              <img src={Cv} alt="cv à voir" className="cv-image" />
              <span className="cv-download">VOIR CV</span>
            </div>
          </a>
        </div>
      </div>

      <Loader type="pacman" active className="loader-custom" />
    </div>
  )
}

export default Home
